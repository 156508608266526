import React from 'react';
import Content from './content/content';
import Columns from './content/columns';

const RenderBlock = (props) => {
  const { block } = props;

  switch (true) {
    case block.content !== null:
      return <Content content={block.content} />;
    case block.columns !== null:
      return <Columns content={block.columns} background={block.background} />;
    default:
      return null;
  }
};

export default RenderBlock;
