import React from 'react';
import cn from 'classnames';
import Content from './content';
import getBackgroundColor from './getBackgroundColor';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  padding: 40px 20px;
`;

const Columns = ({ content, background }) => {
  const backgroundColor = background ? background.colors.toLowerCase() : '';

  return (
    <Wrapper className={cn(getBackgroundColor(backgroundColor), 'h-full-width')}>
      {content.map((column) => (
        <div key={column.id}>{column && <Content content={column.column} />}</div>
      ))}
    </Wrapper>
  );
};

export default Columns;
