export default function getBackgroundColor(selectedName) {
  switch (selectedName) {
    case 'primary':
      return 'h-bc-primary';
    case 'secondary':
      return 'h-bc-secondary';
    default:
      return '';
  }
}
