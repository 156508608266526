import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import RenderBlock from '../components/renderBlock/renderBlock';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Row from '../components/row/row';
import { isLoggedIn } from '../services/auth';

export const query = graphql`
  query ($id: String!) {
    strapiMember(id: { eq: $id }) {
      id
      title
      slug
      content
    }
  }
`;

const Page = ({ data }) => {
  const page = data.strapiMember;

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/app/login');
    }
  }, []);

  return (
    <Layout>
      <SEO title={page.title} description="" />
      <Row className="wysiwyg-content" marginBottom={50}>
        <h1 dangerouslySetInnerHTML={{ __html: page.title }} />

        {page.content.map((block) => (
          <RenderBlock key={block.id} block={block} />
        ))}
      </Row>
    </Layout>
  );
};

export default Page;
